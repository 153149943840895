.layer-menu-item {
    background: black;
    //border-top: 1px solid black;
    border-right: 1px solid #03a;
    border-left: 1px solid #03a;
    border-bottom: 1px solid #03a;
    color: white;
    padding: 0px 0 3px 3px;
    box-sizing: border-box;
    &.editing {
        border: 1px solid #aff;
        box-shadow: inset 0 0 3px 3px rgba(200, 255, 255, 0.3);
        .layer-name-flex {
            font-family: sans-serif;
            .layer-name-section {
                position: relative;
                top: -1px;
            }
        }
        //   padding: 3px 0 1px 2px;
    }
    .layer-name-flex {
        font-family: sans-serif;
        padding: 5px 0 1px 0;
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        &.dragging {
            background: #22f;
            box-shadow: 1px 1px 1px 2px #22f;
        }
        .layer-management-controls {
            display: flex;
            height: 18px;
            .eyeball-opener {
                position: relative;
                top: -1px;
                margin-right: 10px;
                width: 20px;
                height: 18px;
                cursor: pointer;
            }
            .edit-opener {
                position: relative;
                top: -1px;
                margin-right: 10px;
                width: 20px;
                height: 18px;
                cursor: pointer;
            }
            .options {
                position: relative;
                top: -69%;
                font-size: 180%;
                margin-right: 7px;
                cursor: pointer;
            }
        }
        &.closed {
            margin: 3px 10px 0 0;
        }

        .layer-name-section {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .layer-activate-button {
                padding: 0 8px 0 8px;
            }
            .layer-name {
                font-family: sans-serif;
                flex-grow: 1;
                cursor: pointer;
                position: relative;
                top: 1px;
                &.closed {
                    color: #aaa;
                }
                &.layer-active {
                    color: #fff;
                }
                &.opened {
                    &.editing {
                        color: cyan;
                    }
                }
                &:hover {
                    color: yellow;
                }
            }
        }

        .layer-display {
            position: relative;
            top: -3px;
            font-size: 120%;
            cursor: pointer;
            .arrow-text {
                font-size: 80%;
                margin-right: 8px;
            }
            &.opened-display {
                color: cyan;
            }
            &.closed-display {
                color: red;
            }
        }
    }
}

.default-input-container {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: #000530;
    box-shadow: 0 0 9px rgba(0, 50, 255, 0.5) inset;
    padding: 3px 6px 5px 1px;
    margin: 2px 3px 2px 1px;
    user-select: none;
    input {
        font-family: sans-serif;
    }
    &.input-svg-group {
        .svg-icons-list {
            position: relative;
            left: 4px;
        }
    }
    .named {
        font-family: sans-serif;
        font-size: 92%;
        text-align: left;
        margin-top: 1px;
        margin-left: 5px;
        margin-bottom: 4px;
        color: cyan;
    }
    &.active {
        border: 1px solid #dda000;
    }
}
.editing {
    .default-input-container {
        border: 1px solid rgb(4, 170, 170);
        input {
            font-family: sans-serif;
        }
    }
}
