.save-counter-control {
    position: relative;
    pointer-events: all;
    .dialog {
        position: absolute;
        z-index: 1001;
        top: 30px;
        border: 1px solid #23a;
        //z-index: 1;
        font-size: 110%;
        background: #013;
        color: white;
        .title {
            text-align: center;
            background: #23a;
            padding-bottom: 1px;
            box-shadow:
                0 1px 2px 1px #126,
                inset 1px 1px 2px 1px rgba(255, 255, 255, 0.2);
            display: flex;
            //justify-content: space-between;
            flex-grow: 2;
            //flex-basis: 0;
            //width: 33%;
            //min-width: 33%;
            > div:nth-child(1) {
                width: 16%;
            }
            > div:nth-child(2) {
                width: 84%;
                position: relative;
                top: 3px;
                font-size: 100%;
            }
            > div:nth-child(3) {
                margin-right: 2px;
                &.close-x {
                    button.standard-button.blue {
                        transform: scale(0.7);
                    }
                }
            }
        }
        .name-input {
            margin: 0 10px 10px;
            .apply-date-time {
                text-align: right;
                font-size: 80%;
                color: #0dd;
                padding: 14px 5px 5px 0px;
                cursor: pointer;
            }
            .save-input-inline {
                padding: 0px 0 0px 0px;
                display: flex;
                white-space: nowrap;
                align-items: center;
                justify-content: center;
                div:first-child {
                    margin-right: 5px;
                    text-shadow: 0 0 rgba(255, 255, 255, 0.5);
                }
                input[type="text"] {
                    font-family: sans-serif;
                    width: 320px;
                    font-size: 100%;
                }
            }
        }
        .message-container {
            display: grid;
            place-items: center;
            .message {
                font-size: 90%;
                text-align: left;
                position: relative;
                top: -6px;
                &.red {
                    color: red;
                }
                &.green {
                    color: #9f9;
                    text-shadow: 0 0 #0a0;
                }
            }
        }
        .save-counter-options-container {
            display: inline-block;
            margin: 0 50%;
            transform: translateX(-50%);
            .save-counter-option {
                display: grid;
                grid-template-columns: 180px 180px;
                grid-row: auto auto;
                grid-row-gap: 14px;
                button {
                    transform: scale(0.9);
                    span {
                        color: red;
                        font-size: 150%;
                        line-height: 10%;
                        position: relative;
                        top: 6px;
                        margin-left: 3px;
                    }
                }
            }
        }
        .asterisk-explanation {
            margin: 10px;
            color: #ddd;
            text-align: center;
            font-size: 90%;

            span {
                color: red;
                font-size: 120%;
                line-height: 100%;
                position: relative;
                top: 3px;
                margin-right: 3px;
            }
            p {
                text-align: left;
                font-size: 95%;
                user-select: text;
            }
        }
    }
    .svg-container {
        position: absolute;
        top: 0;
        left: -300px;
        z-index: 1000;
        background: red;
    }
}
