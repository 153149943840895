.counter-front-rear-control {
    position: absolute;
    bottom: 2%;
    left: 9%;
    user-select: none;
    z-index: 100;
    display: flex;
    height: 27px;
    border: 1px solid #559;
    background: #113;
    padding: 1px;
    border-radius: 3px;
    div {
        position: relative;
        cursor: pointer;
        &:hover {
            label {
                color: yellow;
            }
        }
        &.option {
            padding-right: 10px;
            line-height: 150%;
            &:nth-child(2) {
                margin-right: 30px;
            }
        }
        color: #bbb;
        white-space: nowrap;
        input {
            transform: scale(1.1);
            cursor: pointer;
        }
        label {
            font-size: 110%;
            color: #aaa;
            &.hilite {
                color: rgb(7, 245, 245);
            }
            margin-left: 9%;
            cursor: pointer;
        }
        button {
            transform: scale(0.9);
        }
    }
}
