.counter-area-coordinates-readout {
    position: absolute;
    bottom: 3%;
    right: 9%;
    user-select: none;
    z-index: 100;
    div {
        color: #bbb;
        white-space: nowrap;
        span {
            display: inline-block;
            color: rgb(7, 215, 215);
            width: 30px;
        }
    }
}
