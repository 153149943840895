.input-xy-position {
    .coordinate-container {
        display: flex;
        justify-content: center;
        width: 96%;
        margin-left: 4%;
        font-family: sans-serif;
        span {
            margin-right: 5px;
            position: relative;
            top: -2px;
            font-family: sans-serif;
        }
        .slider {
            width: 100%;
            cursor: pointer;
        }
        .text-input-style {
            font-family: sans-serif;
            width: 34px;
            font-size: 100%;
            margin-right: 1px;
            margin-left: 8px;
            margin-right: 2px;
            font-size: 90%;
            height: 17px;
            &.warning {
                background-color: #faa;
            }
         }
        .coordinate-readout {
            font-family: sans-serif;
            font-size: 90%;
            width: 25px;
            margin-top: 1px;
            margin-left: 5px;
            text-align: right;
            color: #ccf;
        }
        .proportional-locker {
            width: 12px;
            height: 12px;
            white-space: nowrap;
            position: relative;
            margin-left: 3px;
            .top-l {
                position: relative;
                top: 2px;
                display: block;
                transform: scale(-1,-1);
            }
            .bottom-l {
                position: relative;
                top: -2px;
                display: block;
                transform: scaleX(-1);
            }
            
            span.proportional-control {
                //display: none;
                z-index: 1;
                position: absolute;
                top: 8px;
                left: -1px;
            }
        }
    }
}
