.grid-control {
    display: flex;
    background: #000;
    color: white;
    padding: 0 0.5rem;
    > div:first-child {
        color: #ddd;
        padding: 0.2rem 0.4rem 0 0;
        white-space: nowrap;
    }
    div:nth-child(2) {
        padding-top: 0.4rem;
        .slider-switch {
            transform: scale(1.2);
        }
    }
}
