.sheet {
    //user-select: none;
    position: relative;
    // aspect-ratio: 1 / 74;
    border: 1px solid rgba(255, 255, 255, 0.4);
    .sheet-menus {
        display: flex;
        background-color: #134;
        &.column {
            flex-direction: column;
        }
        &.row {
            flex-direction: row;
        }
    }
    .sheet-container {
        margin: 1px;
        padding: 1px;
        background: rgba(255, 255, 255, 0.5);
        .sheet-display {
            user-select: none;
            padding: 0;
            margin: 0;
            position: relative;
            //      border: 1px dotted #ddd;
            //     background: radial-gradient(#444 3%, transparent 16%) 0 0, radial-gradient(#555 5%, transparent 16%) 4px 4px,
            //     radial-gradient(rgba(155, 155, 155, 0.1) 25%, transparent 20%) 0 1px,
            //     radial-gradient(rgba(155, 155, 155, 0.1) 25%, transparent 20%) 1px 1px;
            //   background-color: #282828;
            //   background-size: 3px 3px;
            background: #ddd;
            width: 100%;
            //height: 100%;
            aspect-ratio: 0.772727 / 1; // we will need to calculate the correct ratio on the javascript side

            .sheet-display-svg {
                display: block;
            }
            .context-menu {
                position: absolute;
                top: 0;
                left: 0;
                border: 1px solid #8aa;
                border-radius: 12%;
                padding: 4px;
                background: rgba(170, 220, 220, 1);
                div {
                    border-radius: 8%;
                    background: #cff;
                    text-align: center;
                    border: 1px solid rgba(0, 0, 0, 0.4);
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    button {
                        pointer-events: all;
                        display: block;
                        &:first-child {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
