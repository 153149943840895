.printBackground {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    background-color: white;
}

$reset-database-background-color: #eef;
$export-database-background-color: #efe;
$import-database-background-color: #faedea;

.view-app {
    background: #ddd;
    color: black;
    padding: 10px;
    .entry {
        &:not(:first-child) {
            margin-top: 10px;
        }
        display: flex;
        background: #eef;
        border: 1px solid rgba(0, 0, 0, 0.5);
        padding: 9px 10px 10px 10px;
        &.reset {
            background: $reset-database-background-color;
            .entry-reset-buttons {
                text-align: center;
                position: relative;
                button {
                    .text-options {
                        span.op {
                            opacity: 0.5;
                        }
                        span.reset-confirm {
                            position: absolute;
                            left: 50%;
                            transform: translate(-50%, 0);
                        }
                        span.disappear {
                            opacity: 0;
                        }
                    }
                }
                .error {
                    color: red;
                    text-shadow: 0 0 red;
                }
            }
        }
        &.export {
            background: $export-database-background-color;
            .export-container {
                position: relative;
                .comments {
                    padding: 3px 3px 7px 10px;
                    p {
                        margin: 5px 0 5px 0;
                    }
                    p + p {
                        margin: 8px 0 0 0;
                    }
                    &.hide {
                        opacity: 0;
                    }
                }
                .export-database-form {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 10px;
                    button {
                        margin-left: 10px;
                    }
                    .append-date {
                        font-size: 90%;
                        margin-top: 5px;
                        color: #00e;
                        text-shadow: 0 0 rgba(0, 0, 0, 100.5);
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
        &.import {
             background: $import-database-background-color;
            .import-ready {
                position: relative;
                top: -2px;
                white-space: nowrap;
                font-size: 95%;
                p {
                    margin: 0;
                }
                .import-info-box {
                    margin: 2px 5px 0 5px;
                    border: 1px solid rgba(0,0,100,0.5);
                    padding: 5px;
                    background: rgba(255,255,255,0.5);
                    > p:nth-child(1) {
                        margin: 0;
                        text-shadow: 0 0 #000;
                    }
                    p {
                        span {
                            color: blue;
                        }
                    }
                    .action-buttons {
                        display: flex;
                        flex-direction : column;
                        justify-content: center;
                        button {
                            margin: 8px 0 3px 0;
                            &:first-child {
                                margin: 6px 0 0 0;
                            }
                            align-self: center;
                        }
                    }
                }
            }
            .error {
                margin-top: 10px;
                font-size: 80%;
                line-height: 98%;
                color: red;
                text-shadow: 0 0 #a00;
            }
        }

        > div:first-child {
            padding: 1px 0px 0px 0px;
        }
        .info {
            background: rgba(190, 190, 190, 0.1);
            margin: 0 0 0 10px;
            border: 1px solid rgba(0, 0, 255, 0.3);
            .title {
                text-shadow: 0 0 rgba(0, 0, 0, 0.8);
                font-size: 115%;
                backdrop-filter: brightness(95%);
                padding: 1px 0 3px 8px;
                border-bottom: 1px solid rgba(0, 0, 255, 0.2);
            }

            .comments {
                padding: 3px 3px 7px 10px;
                p {
                    margin: 5px 0 5px 0;
                }
                p + p {
                    margin: 8px 0 0 0;
                }
            }
        }
    }
}
