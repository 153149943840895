.menu {
    //display: flex;
    margin: 0;
    padding: 0;
    //position: relative;
    z-index: 1001;
    .opened {
        z-index: 1002;
        position: absolute;
        top: 24px;
        //left: -8px;
        width: calc(100% - 5px);
        height: 100%;
        background: white;
    }
    .closed {
        display: none;
    }
}