.dexie-activity-icon {
  display: flex;
  div:first-child {
    margin-right: 5px;
    margin-top: 3px;
    color: #077;
  }
  .dual-ring {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  .dual-ring:after {
    content: " ";
    display: block;
    width: 13px;
    height: 13px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid #000;
    border-color: #099 transparent #099 transparent;
    animation: dual-ring 1s linear infinite;
  }
  @keyframes dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
