.printBackground {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    background-color: white;
  }

.view-saved-sheets {
    background: #ddd;
    color: black;
    padding: 10px;
    .item {
        display: flex;

        //&:not(:first-child) {
        border-bottom: 1px solid black;
        margin-bottom: 11px;
        // }

        .display {
           // display: flex;

            // max-width: 100px;
            // max-height: 100px;
            // width: 500px;
            // height: 500px;
            background: repeating-linear-gradient(-45deg, #eef, #eef 1%, #ddd 2%, #ddd 3%);
            border: 1px solid #555;
            resize: both;
            overflow: auto;
            overflow: hidden;
            user-select: none;
        }
        .form-name-container {
            margin: 0 20px 0 10px;
            max-height: 125px;
            width: 300px;
            max-width: 300px;
            min-width: 300px;
            flex-grow: 1;
            .name-div {
                span:first-child {
                    font-size: 110%;
                    color: #00c;
                    text-shadow: 0 0 #00c;
                }
                margin-bottom: 8px;
            }
            .message {
                text-align: center;
                height: 18px;
                font-size: 94%;
                line-height: 100%;
                text-shadow: 0 0 #090;
                letter-spacing: 0.01rem;
            }
            .rename-div {
                .input-control {
                    display: flex;
                    flex-wrap: nowrap;
                    span {
                        margin-right: 5px;
                    }
                    input[type="text"] {
                        font-family: sans-serif;
                        font-size: 100%;
                        width: 70%;
                        &.warning {
                            background: #fcc;
                        }
                    }
                }
                .name-warning {
                    color: red;
                    height: 20px;
                    background: #aaf;
                    margin: 1px 0 0 0;
                    text-shadow: 0 0 red;
                    white-space: nowrap;
                }

                .message {
                    text-align: center;
                    padding: 3px 0 7px 0;
                }
            }
            .submit-div {
                .actions {
                    position: relative;
                    display: flex;
                    justify-content: space-around;
                    padding-top: 20px;
                    &:first-child {
                        padding-top: 0;
                    }
                    button {
                        height: 26px;
                    }
                    div {
                        width: 74px;
                        position: relative;
                        span {
                            position: absolute;
                            left: -5px;
                            top: -2px;
                            font-size: 90%;
                            color: #d00;
                            text-shadow: 0 0 #a00;
                            width: 110px;
                            line-height: 90%;
                        }
                    }
                }

            }

            .delete-svg-div {
                align-self: center;
                margin-left: 10px;
            }
        }

        .layer-data {
            border-left: 1px solid #aaa;
            padding-left: 8px;
            .date-saved {
                span {
                    color: blue;
                    text-shadow: 0 0 blue;
                }
            }
            .data-name-value {
                border: 1px solid #aaa;
                padding: 4px 8px 4px 4px;
                margin-bottom: 2px;
                background-color: #eaeaf3;
                .data-name {
                    margin-right: 7px;
                }
                .data-value {
                    color: blue;
                    text-shadow: 0 0 blue;
                }
            }
        }

        .metadata {
            flex-grow: 2;
            position: relative;
            top: -2px;
            .size {
                span {
                    color: blue;
                    text-shadow: 0 0 blue;
                    margin: 0 5px 0 1px;
                }
            }
            .size-warning {
                margin-top: 3px;
                background-color: #fdd;
                padding: 2px 5px;
                text-shadow: 0 0 black;
            }
            .code-display {
                textarea {
                    width: 98%;
                    height: 72px;
                }
            }
        }
    }
}
