.error-messages {
    pointer-events: all;
    background: #ddd;
    color: black;
    min-width: 500px;
    max-width: 700px;
    position: absolute;
    z-index: 1001;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 6px 6px 6px 3px rgba(0, 0, 0, 0.2);
    opacity: 0;
    &.fade-in {
        transition: opacity 200ms;
        opacity: 1;
    }
    .container {
        border: 1px solid #abc;
        border-radius: 8px;
        background: #eafaff;
        .top {
            display: flex;
            justify-content: space-between;
            padding: 3px 5px 5px 5px;
            background-color: #cdf;
            user-select: none;
            > div:nth-child(1) {
                font-size: 110%;
                line-height: 150%;
                color: #00c;
                text-shadow: 0 0 rgba(0, 0, 255, 0.4);
                width: 30%;
                white-space: nowrap;
                font-style: italic;
                padding-top: 1px;
            }
            > div:nth-child(2) {
                position: relative;
                width: 30%;
                height: 28px;
                display: inline-block;
                width: 27px;
                height: 27px;
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMyIDMyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMiAzMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxnIGlkPSJtb3ZlIj48Zz48cG9seWdvbiBzdHlsZT0iZmlsbDojMDMwMTA0OyIgcG9pbnRzPSIxOCwyMCAxOCwyNiAyMiwyNiAxNiwzMiAxMCwyNiAxNCwyNiAxNCwyMCAiLz48cG9seWdvbiBzdHlsZT0iZmlsbDojMDMwMTA0OyIgcG9pbnRzPSIxNCwxMiAxNCw2IDEwLDYgMTYsMCAyMiw2IDE4LDYgMTgsMTIgIi8+PHBvbHlnb24gc3R5bGU9ImZpbGw6IzAzMDEwNDsiIHBvaW50cz0iMTIsMTggNiwxOCA2LDIyIDAsMTYgNiwxMCA2LDE0IDEyLDE0ICIvPjxwb2x5Z29uIHN0eWxlPSJmaWxsOiMwMzAxMDQ7IiBwb2ludHM9IjIwLDE0IDI2LDE0IDI2LDEwIDMyLDE2IDI2LDIyIDI2LDE4IDIwLDE4ICIvPjwvZz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==);
                &:hover {
                    background-color: yellow;
                    cursor: grab;
                }
            }
            > div:nth-child(3) {
                width: 30%;
                text-align: right;
            }
        }
        .grab {
            position: absolute;
            top: 2px;
            width: 100%;
            z-index: 0;
            .grab-icon {
                border-radius: 6px;
                border: 1px solid #aaa;
                margin: auto;
                width: 50px;
                height: 25px;
                background-color: gray;
                background-image: linear-gradient(#bbd 0, #88a 35%, #99a 55%, #444 75%);
                background-size: 5px 5px;
                z-index: 0;
            }
        }
        .content-area {
            padding: 0 10px 0 8px;
            .message-comment {
                font-size: 110%;
                text-shadow: 0 0 black;
            }
            &.counter-margins,
            &.page-margins {
                text-align: center;
            }
            p {
                span {
                    text-shadow:
                        0.01rem 0.01rem rgba(0, 0, 0, 0.8),
                        -0.01rem -0.01rem rgba(0, 0, 0, 0.8);
                }
            }
            .float-container {
                .svg-printable-area {
                    float: left;
                    padding: 0 10px;
                }
                .wrap-text {
                    a {
                        font-size: 90%;
                    }
                }
            }
            &.counter-size {
                p {
                    table.table-main {
                        border: 2px solid #888;
                        border-radius: 6px;
                        background: #888;
                        th {
                            background: black;
                            color: white;
                        }
                        th {
                            vertical-align: top;
                            &:not(:last-child) {
                                border-right: 1px solid #999;
                            }
                            padding: 2px 3px;
                        }
                        td {
                            vertical-align: top;
                            &:not(:last-child) {
                                border-right: 1px solid #888;
                            }
                        }
                        table.table-sub {
                            th {
                                padding: 0 5px;
                            }
                            border-collapse: collapse;
                            tbody tr:nth-child(odd) {
                                background-color: #eeeeee;
                            }
                            tbody tr:nth-child(even) {
                                background-color: #ffffff;
                            }
                            td {
                                text-align: center;
                                padding-top: 8px;
                                .mm {
                                    letter-spacing: 0.08rem;
                                    font-size: 90%;
                                    margin-left: 1px;
                                }
                                > span {
                                    margin-right: 3px;
                                    font-size: 119%;
                                    &.td-span {
                                        font-size: 100%;
                                        margin-left: 3px;
                                    }
                                }
                                &.inch {
                                    span.inch-value {
                                        position: relative;
                                        top: -2px;
                                    }
                                }
                                div {
                                    display: inline-block;
                                    position: relative;
                                    top: -3px;

                                    margin-right: 1px;

                                    &.fraction span {
                                        &:nth-child(1) {
                                            font-size: 85%;
                                            position: relative;
                                            top: -3px;
                                        }
                                        &:nth-child(3) {
                                            font-size: 85%;
                                            position: relative;
                                            top: 2px;
                                        }
                                    }
                                }
                            }
                        }
                        .dq {
                            font-size: 110%;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
            }
            &.cutting-guides {
                .guides-graphic {
                    user-select: none;
                    font-size: 110%;
                    letter-spacing: 0.01rem;
                    span:nth-child(1) {
                        margin: 0 25px 0 27px;
                        text-align: center;
                    }
                    span:nth-child(2) {
                        margin: 0 97px 0 97px;
                        text-align: center;
                    }
                    span:nth-child(3) {
                        margin: 0 0px 0 103px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
