.sheet-controls {
    //background: red;
    color: white;
    padding: 2px 1px 5px 10px;
    display: flex;
    gap: 30px;
    user-select: none;
    .controls-opener {
        //height: 40px;
        vertical-align: bottom;
        text-align: center;
        .title {
            padding: 2px;
            margin-top: 1px;
           margin-bottom: 10px;
        }
        &.closed {
            text-align: center;
            .title {
                // padding: 1px;
                display: inline;
            }
        }
        button {
            transform: scale(0.9);
            text-align: center;
            &.closed {
                display: none;
            }
        }
    }
    .controls {
        display: flex;
        gap: 10px;
        .counters-layout {
            padding: 6px 10px 5px 10px;
            border-radius: 10px;
            background: #353577;
            border: 1px solid #aaa;
            display: flex;
            flex-direction: column;
            gap: 3px;
            .select-container {
                align-self: end;
                color: white;
                .popupable {
                    margin-right: 5px;
                }
                select {
                    transform: scale(1.1);
                    width: 40px;
                    font-family: sans-serif;
                    @-moz-document url-prefix() {
                        width: 45px;
                      }
                }
                &.maximum-checked {
                    color: #aaa;
                }
            }
            .maximum-checkbox {
                text-align: center;
                padding: 4px 0 1px 0;
                color: #aaa;
                input[type="checkbox"] {
                    transform: scale(1.1);
                }
                &.maximum-checked {
                    color: white;
                }
            }
        }

        .counter-size {
            padding: 6px 10px 5px 10px;
            @-moz-document url-prefix() {
                padding: 6px 23px 5px 10px;
              }
            border-radius: 10px;
            background: #353577;
            border: 1px solid #aaa;
            //width: 150px;
            .standard-sizes-container {
                div.title {
                    padding-bottom: 2px;
                    .popupable {
                        margin-right: 2px;
                    }
                    .current-value {
                        color: cyan;
                        text-shadow: 0 0 cyan;
                        margin-left: 2px;
                    }
                }
                select {
                    position: relative;
                    left: 6px;
                    transform: scale(1.1);
                    font-family: sans-serif;
                }
            }
            .custom-size {
                display: flex;
                text-wrap: nowrap;
                padding-top: 8px;
                > :first-child {
                    position: relative;
                    top: 3px;
                    margin-right: 1px;
                }
                .input-container {
                    padding-left: 3px;
                    text-align: center;
                    position: relative;
                    top: 1px;
                    input[type="text"] {
                        font-family: sans-serif;
                        width: 60px;
                        text-align: center;
                        font-size: 110%;
                        padding: 1px 0 0 0px;
                        margin: 0;
                        outline: none;
                        border: 0px;
                        vertical-align: baseline;
                        &.not-numeric {
                            background: #fbb;
                        }
                    }
                    .mm {
                        font-size: 90%;
                        position: relative;
                        top: 3px;
                        padding: 0 0 0 3px;
                    }
                }
            }
        }
        .margins-control {
            padding: 6px 10px 5px 10px;
            @-moz-document url-prefix() {
                padding: 6px 10px 5px 14px;
              }
            border-radius: 10px;
            background: #353577;
            border: 1px solid #aaa;
            display: flex;
            flex-direction: column

;
            gap: 3px;
            .title {
                span {
                    font-size: 80%;
                    margin-left: 4px;
                }
            }
            .margins {
                width: 100%;
                //background-color: #fff;
                display: grid;
                grid-template-columns: 68px 108px;
                grid-row: auto auto;
                grid-row-gap: 3px;

                .margin {
                    padding: 2px;
                    color: #fff;
                    display: flex;
                    gap: 3px;
                    align-items: flex-end;
                    justify-content: flex-end;
                    input[type="text"] {
                        font-family: sans-serif;
                        width: 37px;
                        font-size: 105%;
                        padding: 1px 0 0 3px;
                        margin: 0;
                        outline: none;
                        border: 0px;
                        vertical-align: baseline;
                        &.not-numeric {
                            background: #fbb;
                        }
                    }
                }
            }
        }
        .sides-control {
            padding: 6px 10px 5px 10px;
            border-radius: 10px;
            background: #353577;
            border: 1px solid #aaa;
            display: flex;
            flex-direction: column

;
            gap: 2px;
            .title {
                span {
                    font-size: 80%;
                    margin-left: 4px;
                }
            }
            .sides {
                margin: 0 auto;
                .side {
                    //display: flex;
                    cursor: pointer;
                    .option {
                        margin-top: 3px;
                        display: flex;
                        input {
                            cursor: pointer;
                        }
                        label {
                            margin: 1px 1px 0 3px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
