.install-custom-svg {
    .bounder {
        background: rgba(155,255,110,0.1);
        border: 1px solid black;
        width: 500px;
        height: 500px;
    }
    background: #ddd;
    color: black;
    display: flex;
    padding: 10px;
    .success {
        padding: 175px 0 0 20px;
        .success-text {
            span {
                color: rgb(8, 0, 255);
            }
        }
        .success-buttons {
            display: flex;
            padding: 10px 0 0 0;
            button:first-child {
                margin-right: 20px;
            }
        }
    }
    .svg-container {
        width: 400px;
        height: 400px;
        background: #aaa;
        position: relative;
        background-size: 10px 10px; //9.97px 9.97px;
        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        box-sizing: border-box;
        background-image: linear-gradient(to right, #888 1px, transparent 1px),
            linear-gradient(to bottom, #888 1px, transparent 1px);
        &.svg-loaded {
            .drop-text {
                display: none;
            }
            .svg-target {
                display: block;
            }
        }
        .drop-text {
            &.drag-enter {
                border: 1px solid blue;
                box-sizing: border-box;
                background: rgba(255,255,255,0.5);
            }
            border: none;
            width: 400px;
            height: 400px;
            text-align: center;
            color: #333;
            div {
                position: relative;
                top: 30%;
                font-size: 240%;
                span {
                    display: block;
                }
                span:nth-child(2) {
                    text-shadow: 0 0 black;
                }
            }
            // > :nth-child(1),
            // :nth-child(3) {
            //     font-size: 180%;
            // }
            // > :nth-child(2) {
            //     font-size: 280%;
            // }
        }
        #dropArea {
           position: absolute;
           top: 0;
           left: 0;
           width: 400px;
           height: 400px;
        }
        .svg-target {
            width: 100%;
            height: 100%;
            display: none;
        }

        svg:nth-child(1) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        svg:nth-child(2) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        svg:nth-child(3) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .form-area {
        white-space: nowrap;
        margin: 50px 10px 0 20px;
        .info {
            background-color: #f0f4ff;
            padding: 8px 10px;
            border-radius: 10px;
            border: 1px solid #04a;
            box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
            p {
                margin: 5px 0;
            }
        }
        form {
            margin: 45px 0 0 0;
            .choose {
                margin-bottom: 10px;
            }
            .close {
                margin-top: 20px;
            }
            .load-error {
                margin: 10px 0 0 0;
                height: 22px;
                color: #d00;
                text-shadow: 0 0 #944;
            }
            input[type="file"] {
                font-size: 120%;
            }
            .line-1 {
                margin-bottom: 15px;
                span {
                    text-shadow: 0 0 rgba(0, 0, 0, 0.8);
                }
            }
            .line-2 {
                display: flex;
                > div:nth-child(1) {
                    margin: 2px 3px 0 0;
                }
                input[type="text"] {
                    font-family: sans-serif;
                    font-size: 120%;
                    &.warning {
                        background: #fee;
                    }
                }
                button {
                    margin-left: 12px;
                }
            }
            .line-3 {
                margin: 5px 0 0 0;
                height: 22px;
                color: #d00;
                text-shadow: 0 0 #944;
            }
            .line-4 {
                display: flex;
                padding: 5px 0 0 0;
                button:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
}
