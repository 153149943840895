.layers-menu {
    background: white;
    user-select: none;
    > div {
        background: black;
    }
    .layer-menu-item {
        .drop-area {
            transform: scale(0);
            height: 0;
            opacity: 0;
            transition: transform 50ms ease-in 0;
        }
    }
    .layer-dragging {
        .layer-menu-item {
            opacity: 0.5;
        }
    }
    .drop-active {
        .layer-menu-item {
            border-bottom: 1px solid black;
            .drop-area {
                transition: transform 50ms ease-in 0ms;
                position: relative;
                opacity: 1;
                left: -1px;
                margin: 0px 0;
                height: 18px;
                color: black;
                text-shadow: 0.05rem 0.05rem rgba(255,255,255,0.2), -0.05rem -0.05rem rgba(100,100,100, 0.2);
                transform: scale(1);
                background: #aaa;
                text-align: center;
                box-shadow: 0 2px 2px 1px rgba(200,200,200,0.8), 0 2px 2px 2px rgba(100,100,100,0.5) inset;
                position: relative;
                top: 1px;
            }
        }
    }
}
