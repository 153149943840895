body {
  margin: 0;
  font-family: 'sans-serif', 'Segoe UI', 'Roboto', 'Oxygen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.display-none {
  display: none;
}

input[type="text"] {
  font-family: sans-serif;
}

input[type="file"] {
  font-family: sans-serif;
}

input[type="file"]::file-selector-button {
  font-family: sans-serif;
}