.layer-rename-form {
    border: 1px solid #00f;
    margin: 3px 0 15px 0;
    padding: 3px 5px;
    color: #fff;
    text-align: left;
    background: #005;
    div {
        padding: 3px 0;
    }
    div:nth-child(1) {
        font-size: 90%;
        text-align: center;
    }
    div:nth-child(2) {
        text-align: center;
    }
    div:nth-child(3) {
        margin-top: 2px;
        display: flex;
        justify-content: center;
        button {
            margin: 0 5px;
        }
    }
    input[type=text] {
        margin-top: 2px;
        max-width: 200px;
        font-size: 110%;
        &.warning {
            background: #fcc;
        }
    }
}

