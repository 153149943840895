.input-counter-shapes {
    padding-bottom: 10px;
    .message {
        color: white;
        padding-bottom: 15px;
    }
    .selections {
        margin-top: 10px;
        display: flex;            
        >div:first-child {
            padding-left: 20px;
        }
        >div:nth-child(3) {
            padding-right: 20px;
        }

        justify-content: space-around;
        .selection {
            width: 70px;
            cursor: pointer;
            color: #ccc;
            text-align: center;
            div:nth-child(2) {
                padding-top: 5px;
            }
            .square {
                width: 30px;
                height: 30px;
                margin: 0 auto;
                background-color: gray;
                box-shadow: -1px -1px 3px 1px rgba(255, 255, 255, 0.8), inset -1px -1px 3px 1px #555;
            }
            .circle {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin: 0 auto;
                background-color: gray;
                box-shadow: -1px -1px 3px 1px rgba(255, 255, 255, 0.8), inset -1px -1px 3px 1px #555;
            }
            .rectangle {
                width: 35px;
                height: 18px;
                margin: 5px auto 7px auto;
                background-color: gray;
                box-shadow: -1px -1px 3px 1px rgba(255, 255, 255, 0.8), inset -1px -1px 3px 1px #555;
            }
            &.selected {
                color: white;
                .square, .circle, .rectangle {
                    background-color: #BED6FF;
                }
            }
            &:hover {
                color: yellow;
                .square, .circle, .rectangle {
                    background-color: yellow;
                    box-shadow: -1px -1px 3px 1px rgba(255, 255, 155, 1), inset -1px -1px 3px 1px #aa0;
                }
            }
        }
    }
}
