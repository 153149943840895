.input-svg-group {
   .group-label {
      font-size: 90%;
      padding: 5px 0 0 10px;
      .hovering-on {
         color: yellow;
         margin-left: 10px;
      }
   }
   .svg-buttons {
      display: grid; /* 1 */
      grid-template-columns: repeat(auto-fill, 60px); /* 2 */
      justify-content: space-around; /* 4 */
      margin: 0 2% 0 0;

      &.large {
         display: grid; /* 1 */
         grid-template-columns: repeat(auto-fill, 100px); /* 2 */
         justify-content: space-around; /* 4 */
         margin: 0 2% 0 0;
      }
   }
   .input-instruction {
      display: inline-block;
      .input-required {
         display: inline-block;
         font-size: 24px;
         color: red;
         line-height: 0;
         position: relative;
         background: white;
         top: 6px;
         left: 2px;
      }
   }
   .named-flex {
      display: flex;
      justify-content: space-between;
      margin-right: 10px;
      font-family: sans-serif;
      .svgs-display {
         position: relative;
         top: -1px;
         font-size: 120%;
         .arrow-text {
            font-size: 80%;
            margin-right: 8px;
         }
         &.opened-display {
            color: cyan;
         }
         &.closed-display {
            color: red;
         }
      }
   }
   .svg-icons-list {
      &.closed {
         display: none;
      }
   }
}
