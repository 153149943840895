.overlay {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    pointer-events: none;
}

