.standard-button {
    font-family: sans-serif;
    font-size: 110%;
    border-radius: 7px;
    border: 1px solid white;
    padding-top: 2px;
    margin: 0 1px;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    &.blue {
        border: 1px solid rgba(255, 255, 255, 0.5);
        background: #05f;
        background: linear-gradient(0deg, rgba(20, 30, 255, 1) 0%, rgba(5, 15, 200, 1) 50%, rgba(30, 70, 255, 1) 100%);
        color: white;
        outline: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0.02rem 0.02rem 0.02rem 0.02rem rgba(0,0,0,0.2);
        &:hover {
            background: linear-gradient(
                0deg,
                rgba(50, 80, 255, 1) 0%,
                rgba(15, 25, 200, 1) 50%,
                rgba(50, 80, 255, 1) 100%
            );
        }
        &:active {
            color: #d0ffff;
            background: linear-gradient(
                0deg,
                rgba(10, 50, 200, 1) 0%,
                rgba(20, 15, 220, 1) 50%,
                rgba(0, 0, 190, 0.7) 100%
            );
            box-shadow: none;
            > div {
                position: relative;
                top: 0.02rem;
                color: cyan;
            }
        }
    }
    &.yellow {
        //  border: 1px solid rgba(255,255,255,0.5);
        //  background: rgb(255,255,204);
        border: 1px solid rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 0.05rem 0.05rem rgba(255, 255, 255, 0.2);
        background: linear-gradient(
            0deg,
            rgb(230, 230, 130) 2%,
            rgb(240, 240, 120) 4%,
            rgb(235, 230, 74) 3%,
            rgb(240, 240, 30) 50%,
            rgb(250, 250, 60) 100%
        );
        &:hover {
            background: linear-gradient(
                0deg,
                rgb(235, 240, 130) 2%,
                rgb(245, 250, 120) 4%,
                rgb(240, 240, 74) 3%,
                rgb(245, 250, 30) 50%,
                rgb(255, 255, 60) 100%
            );
        }
        &:active {
            background: linear-gradient(
                0deg,
                rgb(200, 200, 100) 2%,
                rgb(130, 130, 90) 4%,
                rgb(222, 222, 70) 3%,
                rgb(200, 200, 20) 50%,
                rgb(240, 220, 65) 98%,
                rgb(230, 230, 130) 100%
            );
            > div {
                position: relative;
                top: 0.01rem;
                color: #d00;
            }
        }
    }
    &.green {
        //  border: 1px solid rgba(255,255,255,0.5);
        //  background: rgb(255,255,204);
        color: white;
        border: 1px solid rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 0.05rem 0.05rem rgba(255, 255, 255, 0.2);
        background: linear-gradient(
            0deg,
            rgb(20, 110, 20) 2%,
            rgb(60, 120, 60) 4%,
            rgb(70, 130, 70) 3%,
            rgb(70, 130, 70) 50%,
            rgb(65, 125, 65) 98%,
            rgb(45, 90, 45) 100%
        );
        &:hover {
            background: linear-gradient(
                0deg,
                rgb(30, 120, 30) 2%,
                rgb(70, 130, 70) 4%,
                rgb(80, 140, 80) 3%,
                rgb(80, 140, 80) 50%,
                rgb(75, 135, 75) 98%,
                rgb(55, 100, 55) 100%
            );
        }
        &:active {
            background: linear-gradient(
                0deg,
                rgb(0, 90, 0) 2%,
                rgb(50, 110, 50) 4%,
                rgb(60, 120, 60) 3%,
                rgb(60, 120, 60) 50%,
                rgb(55, 115, 55) 98%,
                rgb(35, 100, 55) 100%
            );
            > div {
                position: relative;
                top: 0.01rem;
                color: #d00;
            }
        }
    }
    &.red {
        //  border: 1px solid rgba(255,255,255,0.5);
        //  background: rgb(255,255,204);
        color: white;
        border: 1px solid rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 0.05rem 0.05rem rgba(255, 255, 255, 0.2);
        background: linear-gradient(
            0deg,
            rgb(150, 20, 20) 2%,
            rgb(175, 35, 40) 4%,
            rgb(180, 0, 0) 3%,
            rgb(180, 0, 0) 50%,
            rgb(175, 35, 35) 98%,
            rgb(170, 20, 25) 100%
        );
        &:hover {
            background: linear-gradient(
                0deg,
                rgb(160, 30, 30) 2%,
                rgb(185, 45, 50) 4%,
                rgb(190, 10, 10) 3%,
                rgb(190, 10, 10) 50%,
                rgb(185, 45, 45) 98%,
                rgb(160, 30, 35) 100%
            );
        }
        &:active {
            background: linear-gradient(
                0deg,
                rgb(180, 60, 40) 2%,
                rgb(195, 65, 60) 4%,
                rgb(200, 20, 20) 3%,
                rgb(200, 20, 20) 50%,
                rgb(195, 55, 55) 98%,
                rgb(180, 40, 45) 100%
            );
            > div {
                position: relative;
                top: 0.01rem;
                color: #d00;
            }
        }
    }
    &.disabled {
        background: #ddd;
        color: #555;
        cursor: not-allowed;
        opacity: 0.8;
    }
}
