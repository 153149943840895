.counter-area {
    width: 100%;
    height: 100%;
    background: black;
    display: inline-block;
    position: relative;
    transition: top 100ms ease-in 0s;
    top: 0;
    .whiter {
        color: white;
    }
    #baseCounterSvg {
        background-color: #00d;
    }
    .pin-control {
        width: 4%;
        height: 4%;
    }
    .drawing-area {
        .experimental {
            color: yellow;
            font-weight: bold;
            font-size: 100%;
            position: absolute;
            top: 1%;
            left: 5%;
            letter-spacing: 0.1rem;
            z-index: 2000;
        }
        width: 100%;
        height: 100%;
        position: relative;
        background:
            radial-gradient(black 15%, transparent 16%) 0 0,
            radial-gradient(black 15%, transparent 16%) 8px 8px,
            radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px,
            radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
        background-color: #282828;
        background-size: 16px 16px;
        &.three-dee {
            background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
                linear-gradient(-45deg, #808080 25%, transparent 25%),
                linear-gradient(45deg, transparent 75%, #808080 75%),
                linear-gradient(-45deg, transparent 75%, #808080 75%);
            background-size: 20px 20px;
            background-position:
                0 0,
                0 10px,
                10px -10px,
                -10px 0px;
            background-color: #777;
        }
        perspective: 3300px;
        .draw-layer.flip-rear {
            animation: flip-rear 600ms;
            animation-iteration-count: 1;
            animation-timing-function: ease-out;
        }
        .draw-layer.flip-front {
            animation: flip-front 600ms;
            animation-iteration-count: 1;
            animation-timing-function: ease-out;
        }
        &.move-to-sheet-down {
            animation: shrink-and-fly-down 500ms;
            animation-iteration-count: 1;
        }
        &.move-to-sheet-right {
            animation: shrink-and-fly-right 500ms;
            animation-iteration-count: 1;
        }
    }

    @keyframes flip-rear {
        0% {
            transform: rotateY(0deg);
        }
        20% {
            transform: rotateY(10deg);
        }
        40% {
            opacity: 1;
        }
        50% {
            transform: rotateY(90deg);
            opacity: 0;
        }
        60% {
            transform: rotateY(90deg);
            opacity: 0;
        }
        61% {
            transform: rotateY(-90deg);
            opacity: 0;
        }
        70% {
            opacity: 1;
        }
        100% {
            transform: rotateY(0deg);
        }
    }
    @keyframes flip-front {
        0% {
            transform: rotateY(0deg);
        }
        20% {
            transform: rotateY(-10deg);
        }
        40% {
            opacity: 1;
        }
        50% {
            transform: rotateY(-90deg);
            opacity: 0;
        }
        60% {
            transform: rotateY(-90deg);
            opacity: 0;
        }
        61% {
            transform: rotateY(90deg);
            opacity: 0;
        }
        70% {
            opacity: 1;
        }
        100% {
            transform: rotateY(0deg);
        }
    }
    @keyframes shrink-and-fly-down {
        0% {
            transform: scale(1);
        }
        30% {
            animation-timing-function: ease-in;
            transform: scale(0.1);
        }
        100% {
            transform: scale(0.1) translateY(20000px);
        }
    }
    @keyframes shrink-and-fly-right {
        0% {
            transform: scale(1);
        }
        30% {
            animation-timing-function: ease-in;
            transform: scale(0.1);
        }
        100% {
            transform: scale(0.1) translateX(20000px);
        }
    }
}
