.three-dee-view-draggers {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    pointer-events: none;
    .label {
        user-select: none;
    }
    .explode-control {
        position: absolute;
        width: 60%;
        left: calc(50% + 1.5vw);

        @media (max-width: 1000px) {
            left: 49%;
        }
        @media (min-width: 1800px) {
            left: 50%;
        }
        height: 100%;
        transform: translate(-50%, 0) rotate(270deg);
        .label {
            text-align: center;
            span {
                color: white;
                padding: 0 2px 2px 2px;
             //   background: rgba(0, 0, 0, 0.5);
                @media (max-width: 750px) {
                    font-size: 0.8rem;
                    position: relative;
                    top: 1px;
                }
            }
        }
        .slider {
            pointer-events: auto;
            position: relative;
            width: 100%;
            input[type="range"] {
                width: 100%; // calc(60% - 10px);
            }
        }
    }

    .rotate-control {
        position: absolute;
        width: 60%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, 0);
        @media (max-width: 820px) {
            transform: translate(-50%, 2%);
        }
        @media (max-width: 880px) {
            transform: translate(-50%, 1%);
        }
        @media (min-width: 1000px) {
            transform: translate(-50%, 0);
        }
        @media (min-width: 1100px) {
            transform: translate(-50%, -1%);
        }
        @media (min-width: 1200px) {
            transform: translate(-50%, -2%);
        }
        @media (min-width: 1800px) {
            transform: translate(-50%, -1%);
            
        }

        .rotate-position {
            position: absolute;
            width: 100%;
            bottom: 1%;
            .label {
                font-size: 1rem;
                text-align: center;
                @media (max-width: 700px) {
                    font-size: 0.8rem;
                    position: relative;
                    top: 1px;
                }
                span {
                    color: white;
                    padding: 0 2px 2px 2px;
                   // background: rgba(0, 0, 0, 0.5);
                    @media (max-width: 750px) {
                        font-size: 0.8rem;
                        position: relative;
                        top: 1px;
                    }
                }
            }
            .slider {
                pointer-events: auto;
                position: relative;
                width: 100%;
                input[type="range"] {
                    width: 100%; // calc(60% - 10px);
                }
            }
        }
    }

    .angle-control {
        position: absolute;
        width: 60%;
        left: calc(47.8%);
        @media (max-width: 1000px) {
            left: 50.5%;
        }
        @media (min-width: 1800px) {
            left: 50%;
        }
        @media (max-width: 744px) {
            left: 51%;
        }
        height: 100%;
        transform: translate(-50%, 0) rotate(90deg);
        .label {
            text-align: center;
            span {
                color: white;
                padding: 0 1% 1% 1%;
             //   background: rgba(0, 0, 0, 0.5);
                @media (max-width: 750px) {
                    font-size: 0.8rem;
                    position: relative;
                    top: 1px;
                }
            }
        }
        .slider {
            transform: rotate(180deg);
            pointer-events: auto;
            position: relative;
            width: 100%;
            input[type="range"] {
                width: 100%; // calc(60% - 10px);
            }
        }
    }
}
