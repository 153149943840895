.view-saved-counters {
    background: #000;
    color: black;
    padding: 1px;
    .display-container {
        display: flex;
        margin-top: 3px;
        background: #ddd;
        .display {
            min-width: 140px;
            min-height: 140px;
            width: 140px;
            height: 140px;
            background: repeating-linear-gradient(-45deg, #eef, #eef 12%, #ddd 14%, #ddd 24%);
            border: 1px solid #555;
            padding: 2px;
            resize: both;
            overflow: auto;
            overflow: hidden;
            user-select: none;
        }
        .form-name-container {
            margin: 8px 0 0 10px;
            .name-div {
                padding-bottom: 10px;
                span:first-child {
                    color: #00c;
                    text-shadow: 0 0 #00c;
                }
            }
            .renamed-div {
                height: 22px;
                color: green;
                font-size: 90%;
                text-shadow: 0 0 #090;
                letter-spacing: 0.01rem;
            }
            .rename-div {
                .flex {
                    display: flex;
                    width: 200px;
                    .input-control {
                        span {
                            margin-right: 5px;
                        }
                        input[type="text"] {
                            font-family: sans-serif;
                            font-size: 100%;
                            width: 190px;
                            &.warning {
                                background: #fcc;
                            }
                        }
                    }
                    .name-warning {
                        color: red;
                        margin: 8px 0 0 10px;
                        text-shadow: 0 0 red;
                        white-space: nowrap;
                    }
                }
                .message {
                    text-align: center;
                    padding: 3px 0 7px 0;
                }
            }
            .submit-div {
                .actions {
                    position: relative;
                    display: flex;
                    justify-content: space-around;
                    button {
                        height: 26px;
                    }
                    div {
                        width: 74px;
                        position: relative;
                        span {
                            position: absolute;
                            left: -8px;
                            top: -2px;
                            font-size: 90%;
                            color: #d00;
                            text-shadow: 0 0 #a00;
                            width: 110px;
                            line-height: 90%;
                        }
                    }
                }
                &.second-row {
                    margin-top: 5px;
                }
            }

            .delete-svg-div {
                align-self: center;
                margin-left: 10px;
            }
            .metadata {
                padding-left: 20px;
                margin-left: 20px;
                width: 100%;
                .size {
                    span {
                        color: blue;
                        text-shadow: 0 0 blue;
                        margin: 0 5px 0 1px;
                    }
                }
                .size-warning {
                    margin-top: 3px;
                    background-color: #fdd;
                    padding: 2px 5px;
                    text-shadow: 0 0 black;
                }
                .code-display {
                    textarea {
                        width: 100%;
                        height: 72px;
                    }
                }
            }
        }
    }
    .layer-data {
        margin: 10px 10px 2px;
        padding: 0 10px;
        text-shadow: 0 0 black;
        border-left: 1px dashed rgba(0, 0, 0, 0.2);
        .date-saved {
            margin-bottom: 3px;
            span {
                color: #00c;
                text-shadow: 0 0 #00c;
            }
        }
        .layers-list {
            display: flex;
            padding-top: 4px;
            padding-bottom: 2px;
            flex-wrap: wrap;
            gap: 8px;
            .layer-background {
                background: black;
                border-radius: 8px;
                box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
                padding: 1px 10px 2px 10px;
                &.hidden {
                    position: relative;
                    top: 2px;
                    left: 2px;
                    background: #777;
                    padding: 1px 8px 0px 10px;
                    margin: 0 2px 0 0;
                    box-shadow: -0.05rem -0.05rem 0.05rem 0.05rem black;
                    &:hover {
                        background: #44d;
                    }
                    .layer-name {
                        font-family: sans-serif;
                        color: #eee;
                        text-shadow: none;
                        position: relative;
                        top: -0.05rem;
                        left: -0.05rem;
                        user-select: none;
                    }
                }
                cursor: pointer;
                &:hover {
                    background: #22a;
                }
                .layer-name {
                    font-family: sans-serif;
                    white-space: nowrap;
                    color: white;
                    text-shadow: 0 0 white;
                    text-align: center;
                    pointer-events: none;
                    user-select: none;
                }
            }
        }
        .extras-list {
            margin-top: 2px;
            margin-bottom: 2px;
            text-shadow: 0 0 black;
            display: flex;
            span { 
                color: blue;
                &:first-child {
                    margin-left: 3px;
                }
            }
        }
    }
}
