.pin-control {
    position: absolute;
    z-index: 100;
    top: 0.2%;
    right: 5%;
    transform: rotate(45deg);
    cursor: pointer;
    &.pinned {
        transform: rotate(0deg);
    }
    
}