.input-color {
    display: flex;
    align-items: center;
    .named-container {
        display: flex;
        flex-wrap: nowrap;
        .named {
            font-family: sans-serif;
            margin-top: 3px;
            height: 18px;
            max-height: 18px;
            overflow: hidden;
            &.shortened {
                text-align: right;
            }
        }
        .colon {
            color: cyan;
            margin: 0 3px;
            position: relative;
            top: 2px;
        }
    }
    input[type="text"] {
        font-family: sans-serif;
        width: 90px;
        font-size: 120%;
        padding: 1px 1px 0 1px;
        margin: 0 5px 0 5px;
        &.input-color-pick {
            background: #e0f0ff;
            &.invalid {
                background: #ffaaaa;
            }
        }
    }
    .color-sample {
        width: 24px;
        height: 24px;
        min-width: 24px;
        margin-top: 1px;
        margin-right: 7px;
        border: 1px solid white;
        box-sizing: border-box;
        cursor: pointer;
    }
    .multicolor-button {
        width: 28px;
        min-width: 28px;
        height: 28px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
    }
}
