.input-width-height {
    .width-height-control {
        width: 97%;
        margin-left: 3%;
        .dimension {
            font-family: sans-serif;
            position: relative;
            top: -2px;
        }
        table {
            font-size: 90%;
            padding: 0;
            td {
                padding: 0;
                margin: 0;
                &.td-readout {
                    white-space: nowrap;
                    padding-right: 1px;
                }
                &.slider {
                    //width: 65%;
                    width: 100%;
                    input[type="range"] {
                        width: 100%;
                        background-color: #9a905d;
                        cursor: pointer;
                    }
                    &.locked {
                        &.width {
                            border-top: 1px solid rgba(255, 255, 255, 0.3);
                            border-right: 1px solid rgba(255, 255, 255, 0.3);
                            border-left: 1px solid rgba(255, 255, 255, 0.3);
                            position: relative;
                            top: 1px;
                            left: 2px;
                        }
                        &.height {
                            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                            border-right: 1px solid rgba(255, 255, 255, 0.3);
                            border-left: 1px solid rgba(255, 255, 255, 0.3);
                            position: relative;
                            top: -1px;
                            left: 2px;
                        }
                    }
                }
                input[type="text"] {
                    font-family: sans-serif;
                    width: 37px;
                    font-size: 100%;
                    margin-right: 1px;
                    &.percent {
                        width: 38px;
                        position: relative;
                        right: 0px;

                        &.windows-chrome {
                            width: 40px;
                            right: 3px;
                        }
                    }
                    &.warning {
                        background-color: #faa;
                    }
                }
            }
            td.aspect-ratio {
                width: 25px;
                cursor: pointer;
                &.inactive {
                    filter: blur(0.01rem);
                    opacity: 0.7;
                }
            }
            width: 100%;
        }
    }
    .coordinate-container {
        font-family: sans-serif;
        display: flex;
        justify-content: center;
        span {
            font-family: sans-serif;
            margin-right: 5px;
            position: relative;
            top: -2px;
            cursor: pointer;
        }
        .slider {
            width: 250px;
            cursor: pointer;
        }
        .proportional-locker {
            width: 12px;
            height: 12px;
            white-space: nowrap;
            position: relative;
            margin-left: 3px;
            .top-l {
                position: relative;
                top: 2px;
                display: block;
                transform: scale(-1, -1);
            }
            .bottom-l {
                position: relative;
                top: -2px;
                display: block;
                transform: scaleX(-1);
            }

            span.proportional-control {
                //display: none;
                z-index: 1;
                position: absolute;
                top: 8px;
                left: -1px;
            }
        }
    }
}
