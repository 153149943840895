.sheet-operations {
    //background: #535;
    padding: 2px 1px 5px 10px;
    &.opened {
        border-top: 1px solid #555;
        padding: 5px 1px 5px 10px;
        background-color: #045;
    }
    color: white;

    display: flex;
    gap: 30px;
    user-select: none;
    .controls-opener {
        //   height: 40px;
        vertical-align: bottom;
        text-align: center;
        .title {
            padding: 2px;
            margin-top: 1px;
            margin-bottom: 10px;
        }
        &.closed {
            text-align: center;
            .title {
                // padding: 1px;
                display: inline;
            }
        }
        button {
            transform: scale(0.9);
            text-align: center;
            &.closed {
                display: none;
            }
        }
    }
    .controls {
        display: flex;
        gap: 10px;
        .controls-container {
            padding: 6px 10px 5px 10px;
            border-radius: 10px;
            background: #254735;
            border: 1px solid #aaa;
            display: flex;
            flex-direction: column;
            gap: 3px;
            white-space: nowrap;
            > .title {
                text-shadow: 0 0 rgba(110, 255, 255, 0.5);
                margin-bottom: 0px;
            }
            > .title-with-button {
                display: flex;
                margin-bottom: 0px;
                button {
                    position: relative;
                    top: -2px;
                    margin-left: 10px;
                    transform: scale(0.8);
                }
            }
            .printable-area-settings {
                div {
                    &:nth-child(1) {
                        margin-bottom: 3px;
                    }
                    div:first-child {
                        display: inline-block;
                        width: 50px;
                        text-align: right;
                    }
                }
                input[type="text"] {
                    font-family: sans-serif;
                    width: 60px;
                    padding: 1px 0 0 2px;
                    margin: 0;
                    font-size: 105%;
                    background-color: #fff;
                    &.warning {
                        background-color: #faa;
                    }
                }
            }
            .options {
                display: flex;
                .title {
                    margin-right: 3px;
                }
                input[type="checkbox"] {
                    transform: scale(1.1);
                    margin-left: 16px;
                }
                label {
                    cursor: pointer;
                }
                &.cutting-guides {
                    &:nth-child(2) {
                        margin-top: 3px;
                    }
                    input[type="radio"] {
                        transform: scale(1.1);
                        margin: 0 6px 0 0;
                    }
                    label {
                        &:nth-child(2) {
                            margin-right: 12px;
                        }
                    }
                }
            }
            .actions {
                input[type="text"] {
                    font-family: sans-serif;
                    width: 130px;
                    font-size: 110%;
                    padding: 0 2px 0 2px;
                }
                button {
                    position: relative;
                    top: 1px;
                    left: 3px;
                    transform: scale(0.9);
                }
                .saved {
                    display: flex;
                    justify-content: space-around;
                    span {
                        align-self: flex-end;
                        margin-bottom: 2px;
                    }
                }
            }

            &.load {
                .title-with-button {
                    justify-content: space-between;
                }
                .load-from {
                    span {
                        margin-right: 10px;
                    }
                    input[type="radio"] {
                        transform: scale(1.1);
                    }
                    label {
                        cursor: pointer;
                        margin: 0 5px 0 0;
                        position: relative;
                        left: -1px;
                    }
                    .selects {
                        margin-top: 5px;
                        select {
                            font-family: sans-serif;
                        }
                    }
                }
                .action {
                    text-align: right;
                    button {
                        position: relative;
                        left: 10px;
                        margin-top: 6px;
                        transform: scale(0.8);
                    }
                }
            }
            .error {
                position: relative;
                button {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%,0);
                    margin-top: 5px
                }
            }
        }

        .counter-size {
            padding: 6px 10px 5px 10px;
            border-radius: 10px;
            background: #353577;
            border: 1px solid #aaa;
            //width: 150px;
            .standard-sizes-container {
                div {
                    padding-bottom: 2px;
                    .current-value {
                        color: cyan;
                        text-shadow: 0 0 cyan;
                        margin-left: 2px;
                    }
                }
                select {
                    position: relative;
                    left: 6px;
                    transform: scale(1.1);
                    font-family: sans-serif;
                }
            }
            .custom-size {
                display: flex;
                text-wrap: nowrap;
                padding-top: 8px;
                > :first-child {
                    position: relative;
                    top: 3px;
                    margin-right: 1px;
                }
                .input-container {
                    padding-left: 3px;
                    text-align: center;
                    position: relative;
                    top: 1px;
                    input[type="text"] {
                        font-family: sans-serif;
                        width: 60px;
                        text-align: center;
                        font-size: 110%;
                        padding: 1px 0 0 0px;
                        margin: 0;
                        outline: none;
                        border: 0px;
                        vertical-align: baseline;
                        &.not-numeric {
                            background: #fbb;
                        }
                    }
                    .mm {
                        font-size: 90%;
                        position: relative;
                        top: 3px;
                        padding: 0 0 0 3px;
                    }
                }
            }
        }
        .margins-control {
            padding: 6px 10px 5px 10px;
            border-radius: 10px;
            background: #353577;
            border: 1px solid #aaa;
            display: flex;
            flex-direction: column;
            gap: 3px;
            > div:nth-child(1) {
                span {
                    font-size: 80%;
                }
            }
            .margins {
                width: 100%;
                //background-color: #fff;
                display: grid;
                grid-template-columns: 68px 105px;
                grid-row: auto auto;
                grid-row-gap: 3px;

                .margin {
                    padding: 2px;
                    color: #fff;
                    display: flex;
                    gap: 3px;
                    align-items: flex-end;
                    justify-content: flex-end;
                    input[type="text"] {
                        font-family: sans-serif;
                        width: 30px;
                        font-size: 105%;
                        padding: 1px 0 0 3px;
                        margin: 0;
                        outline: none;
                        border: 0px;
                        vertical-align: baseline;
                        &.not-numeric {
                            background: #fbb;
                        }
                    }
                }
            }
        }
    }
}
.temp-sheet {
    position: absolute;
    left: -9999px;
    top: -9999px;
}
