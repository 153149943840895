.default-input-container {
    &.input-radio-group {
        .named-container {
            display: flex;
            flex-wrap: nowrap;
            flex: 0;
            .named {
                font-family: sans-serif;
                margin-top: 4px;
                height: 18px;
                max-height: 17px;
                overflow: hidden;
                text-align: right;
                &.disabled {
                    color: #bbb;
                }
            }
            .colon {
                color: cyan;
                margin: 0 0 0 3px;
                position: relative;
                top: 3px;
            }
        }
        .radio-options {
            font-size: 100%;
            display: flex;
            flex: 3;
            div {
                padding: 3px 0 0 4px;
                white-space: nowrap;
                input {
                    position: relative;
                    top: -2px;
                    vertical-align: middle;
                }
                label {
                    margin-left: 2px;
                }
                &:first-child {
                    label {
                        margin-left: 4px;
                        margin-right: 2px;
                    }
                }
            }
        }
        .value-readout {
            font-size: 110%;
            line-height: 18px;
            border: 1px solid black;
            text-align: right;
            padding: 0 2px 0 1px;
            color: #ccf;
            min-width: 31px;
            width: 31px;
            &.percent {
                min-width: 42px;
            }
            &.min-width {
                min-width: 22px;
                width: 22px;
            }
        }
        .slider {
            width: 200px;
        }
    }
}
