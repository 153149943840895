.close-x {
    button.standard-button.blue {
        &:hover {
            background: none;
            background-color: black;
            div {
                color: red;
                text-shadow: 1px 0 red;
            }
        }
        div {
            transform: scaleX(1.4);
            text-shadow: 1px 0 white;
            position: relative;
            left: -0.5px;
        }
    }
}
