.input-slider {
    .named.disabled {
        color: gray;
    }
    .input-flex {
        display: flex;
        justify-content: center;
        align-content: center;
        .input-flex-1 {
            width: 90%;
            .input-flex-slider {
                width: 95%;
                margin-left: 5%;
                padding: 2px 0 2px 0;
                &:disabled {
                    cursor: not-allowed;
                }
            }
        }
        .text-readout {
            font-family: sans-serif;
            white-space: nowrap;
            input[type="text"] {
                font-family: sans-serif;
                font-size: 95%;
                width: 40px;
                &.skinny {
                    width: 32px;
                }
                margin-left: 5px;
            }
            &.warning {
                input[type="text"] {
                    background-color: #faa;
                    font-family: sans-serif;
                }
            }
            span.decoration {
                margin-left: 2px;
                margin-right: 2px;
            }
        }
    }
}