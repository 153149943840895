.layer-activate-button {
    .activate-button {
        width: 16px;
        height: 16px;
        position: relative;
        top: 0.05rem;
        border-radius: 100%;
        background-color: #555;
        cursor: pointer;
        &.active {
            background: radial-gradient(circle at center, #7ff 20%, #0ff 50%, #0cc 60%);
            box-shadow: 0 0 2px 1px rgba(100, 255, 255, 0.4);
            &.yellow {
                background: radial-gradient(circle at center, #ffa 20%, #ff0 50%, #cc0 60%);
                box-shadow: 0 0 2px 1px rgba(255, 255, 0, 0.4);
            }
            &.not-allowed {
                cursor: not-allowed;
            }
        }
        &.off {
            background: radial-gradient(circle at center, #555 20%, #555 50%, #333 60%);
            box-shadow: 0 0 2px 1px rgba(100, 100, 100, 0.4);
        }
        &.current-active {
            background: radial-gradient(circle at center, #7ff 20%, #0ff 50%, #0cc 60%);
            box-shadow: 0 0 2px 1px rgba(100, 255, 255, 0.4);
        }
        &.reverse-active {
            background: radial-gradient(circle at center, #ffa 20%, #ff0 50%, #cc0 60%);
            box-shadow: 0 0 2px 1px rgba(255, 255, 0, 0.4);
        }
        &.not-allowed {
            cursor: not-allowed;
        }
        &:hover {
            background: radial-gradient(circle at center, #f22 20%, #f55 50%, #f77 60%);
            // &.active {
            //     background: radial-gradient(circle at center, #8e8 20%, #9e9 50%, #9e9 60%);
            // }
            &.not-allowed {
                background: #555;
            }
        }
    }
    .activate-button-always-active {
        width: 16px;
        height: 16px;
        position: relative;
        top: 0.14rem;
        border-radius: 100%;
        background-color: #555;
        cursor: pointer;
        background: radial-gradient(circle at center, #7ff 20%, #0ff 50%, #0cc 60%);
        box-shadow: 0 0 2px 1px rgba(100, 255, 255, 0.4);
        cursor: not-allowed;
        &:hover {
            background: radial-gradient(circle at center, #7ff 20%, #0ff 50%, #0cc 60%);
            box-shadow: 0 0 2px 1px rgba(100, 255, 255, 0.4);
        }
    }
}
