.svg-button {
    //width: 3.0em;
    //margin: 0 0.6em 0 0.5em;
    &.large-button {
        width: 90px;
        // border: 1p solid red;
        margin: 10px 8px 10px 8px;
        .svg-code {
            width: 90px;
            height: 90px;
            //padding: 10px;
            margin: 0 0 10px 0;
            > :first-child {
                display: block;
                margin: auto;
                height: 100%;
            }
            &.selected {
                box-shadow: 0em 0em 0.08em 0.08em rgba(0, 255, 255, 0.7);
            }
        }
        .svg-name {
            width: 100%;
            word-break: break-all;
        }
    }
    .svg-code {
        display: flex;
        width: 50px;
        height: 50px;
        overflow: hidden;
        background: #aaa;
        &.national-flags {
            background-color: rgb(50, 50, 50);
            background-image: linear-gradient(45deg, #a0a0a0 25%, transparent 25%),
                linear-gradient(-45deg, #a0a0a0 25%, transparent 25%),
                linear-gradient(45deg, transparent 75%, #a0a0a0 75%),
                linear-gradient(-45deg, transparent 75%, #a0a0a0 75%);
            background-size: 5px 5px;
            background-position:
                0 0,
                0 2.5px,
                2.5px -2.5px,
                -2.5px 0px;
        }

        margin: 10px 6px;
        box-shadow: 0.1em 0.1em 0.1em 0.1em rgba(80, 80, 80, 0.2);
        font-size: 20px;
        cursor: pointer;
        &.selected {
            background: rgb(190, 215, 255);
            box-shadow: 0em 0em 0.06em 0.06em rgba(0, 255, 255, 0.7);
            &.national-flags {
                background-color: rgb(50, 50, 50);
                background-image: linear-gradient(45deg, rgb(190, 215, 255) 25%, transparent 25%),
                    linear-gradient(-45deg, rgb(190, 215, 255) 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, rgb(190, 215, 255) 75%),
                    linear-gradient(-45deg, transparent 75%, rgb(190, 215, 255) 75%);
                background-size: 5px 5px;
                background-position:
                    0 0,
                    0 2.5px,
                    2.5px -2.5px,
                    -2.5px 0px;
            }
        }
        &:not(.selected):hover {
            background: rgb(240, 240, 255);
            //  box-shadow: 0.1em 0.1em 0.1em 0.1em rgba(110, 80, 80, 0.2);
            box-shadow: 0.1em 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.4);
        }
    }
    .svg-name {
        font-size: 90%;
        position: relative;
        top: -5px;
        width: 60px;
        text-align: center;
        &:not(.selected) {
            color: #aaa;
        }
        &.national-flags {
           font-size: 70%;
        }
    }
}
