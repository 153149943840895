.three-dee-view-button {
    position: absolute;
    bottom: 2%;
    right: 2%;
    user-select: none;
    z-index: 101;
    div {
        color: #bbb;
        white-space: nowrap;
        span {
            display: inline-block;
            color: rgb(7, 215, 215);
            width: 30px;
        }
    }
}
