.grid-display {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .grid-display-svg {
        pointer-events: none;
    }
}
