.input-text {
    input[type="text"] {
        font-family: sans-serif;
        margin-left: 5px;
        width: 50px;
        font-size: 100%;
    }
    .input-required {
        display: inline-block;
        font-size: 24px;
        color: red;
         line-height: 0px;
         position: relative;
         top: 3px;
         left: 3px;
    }
}