.layer-options {
    color: white;
    .options {
        border: 1px solid cyan;
        background: #001d1d;
        margin: 8px 2% 3px 2%;
        padding: 5px 0 0px 0;
        display: flex;
        align-items: center;
        flex-direction: column;

        .option {
            margin: 5px;
            text-align: center;

            font-size: 90%;
            span {
                color: cyan;
            }
            &.opened {
                // width: 93%;
                border: none;
                position: relative;
                top: 1px;
                .duplicate-layer {
                    padding-bottom: 1px;
                }
            }
            .option-clicker {
                > div {
                    background: #000;
                    cursor: pointer;
                    display: inline-block;
                    border-radius: 10px;
                    border: 1px solid #aaa;
                    padding: 5px 8px;
                    margin-bottom: 5px;
                    &:hover {
                        // background: cyan;
                        background: #005;
                    }

                }
                &:last-child {
                    margin-bottom: 0px;
                }
                &.disabled {
                    color: #aaa;

                    > div {
                        cursor:  not-allowed;
                        border: 1px solid #555;
                        span {
                            color: #aaa;
                        }
                        &:hover {
                            background: #222;
                        }
                    }
                }
            }
        }
    }
}
