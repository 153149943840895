.top-bar {
    background: #f0f6ff;
    color: black;
    border-radius: 5px;
    width: calc(100% - 5px);
    margin: 1px auto;
    height: 26px;
    box-shadow: inset 0 0 1px 2px rgba(0, 0, 100, 0.3);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 200;
    .dexie-activity-icon {
        position: absolute;
        top: 2px;
        right: 12px;
    }
}
