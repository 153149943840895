.install-font {
    background: #f0f0f0;
    height: calc(100vh - 67px);
    padding: 10px;
    .font-install-explanation {
        color: black;
        background: #c5daee;
        border: 1px solid #559;
        p {
            margin: 10px;
        }
    }
    .google-url {
        padding: 15px 0 0 20px;
    }
    .font-name-issue {
        padding: 3px;
        margin-top: 10px;
        border: 1px solid black;
        background: #fdd;
        span {
            color: #00f;
            text-shadow: 0 0 #00f;
        }
    }
    .font-lookup {
        display: flex;
        gap: 10px;
        padding: 20px 0 0 20px;
        white-space: nowrap;
        span {
            font-size: 110%;
            line-height: 150%;
        }
        >span:nth-child(3) {
            margin-left: 6px;
        }
        input[type="text"] {
            font-family: sans-serif;
            width: 300px;
            font-size: 120%;
        }
        button {
            margin-left: 9px;
        }
    }
    .font-loaded-installed {
        .message {
            margin-bottom: 1px;
        }
        .font-example {
            border: 1px solid black;
            padding: 10px;
            background: white;
            font-size: 200%;
            overflow-wrap: break-word;
        }
        .install-options {
            display: flex;
            padding: 10px;
            button {
                margin-right: 20px;
            }
        }
    }
    .error-message {
        color: red;
        letter-spacing: 0.01rem;
        text-shadow: 0 0 black;
        padding-top: 10px;
        height: 30px;
    }
}
