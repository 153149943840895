.layer-menu-opener-edit {
    font-family: "Material Icons", "sans-serif";
    font-size: 110%;
    &.opened {
        color: white;
    }
    &.closed {
        color: #555;
    }
}
