body {
    background: black;
}
#debug {
    color: white;
}
.tt {
    color: white;
}
.startup {
    height: 100vh;
    width: 100%;
    background: grey;
    color: white;
    cursor: pointer;
    &.anim-to-black {
        animation-name: color_change;
        animation-duration: 360ms;
        animation-fill-mode: forwards;
    }
    .info-container {
        display: flex;
        transform: scale();
        .info {
            font-size: min(2.7vw, 120%);
            background: white;
            border: 1px solid black;
            display: inline-block;
            margin: 10% auto;
            padding: 10px 20px 25px 10px;
            border-radius: 10px;
            p {
                color: black;
                font-size: 150%;
            }
            .centered {
                font-size: 130%;
                color: #00d;
                text-shadow: 0 0 #00d;
                padding-top: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &.anim-away {
            opacity: 0;
            transition:
                transform 360ms ease-in-out,
                opacity 330ms ease-out;
            transform-origin: 50% 60%;
            transform: scale(0);
        }
    }

    @keyframes color_change {
        from {
            background-color: grey;
        }
        to {
            background-color: black;
        }
    }
}
.home {
    &.home-overlay {
        pointer-events: none;
    }
    background: #000;
    position: relative;

    .popupable {
        display: inline-block;
        cursor: help;
        color: rgb(251, 198, 99);
        text-shadow: 0 0 rgb(251, 198, 99);
        white-space: nowrap;
        &:hover {
            color: orange;
            text-shadow: 0 0 orange;
        }
    }
    .display-none {
        display: none;
    }
    .app {
        display: flex;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        &.inline {
            flex-wrap: nowrap;
        }

        &.home-overlay {
            overflow: hidden;
        }
        table {
            .td-cmenu {
                .tabs {
                    display: flex;
                    margin-top: 5px;
                    border-bottom: 1px solid #999;
                    user-select: none;
                    .tab {
                        padding: 4px 10px 4px 8px;
                        margin-left: 5px;
                        border-top-left-radius: 3px;
                        border-top-right-radius: 10px;
                        border-left: 1px solid #fff;
                        border-top: 1px solid #fff;
                        overflow: hidden;
                        &.active {
                            text-shadow: 0 0 rgba(255, 255, 255, 0.1);
                            border-bottom: none;
                            border-right: 1px solid white;
                            color: white;
                            background: #00156a;
                            box-shadow: 0.05rem 0rem 1px 1px rgba(255, 255, 255, 0.5);
                        }
                        &.inactive {
                            color: #999;
                            background: #000;
                            border-left: 1px solid #aaa;
                            border-top: 1px solid #aaa;
                            border-right: 1px solid #aaa;
                            box-shadow: none;
                        }
                        &:hover {
                            color: black;
                            background: yellow;
                            cursor: pointer;
                            border-left: 1px solid #fff;
                            border-top: 1px solid #fff;
                            border-right: 1px solid fff;
                        }
                    }
                }
            }
            &.table-column {
                width: 100%;
                max-width: 1600px;
                td {
                    &.td-cmenu {
                        min-width: 200px;
                        width: 30%;
                        height: 100%;
                        background: #000;
                        vertical-align: top;
                        .cmenu {
                            width: 100%;
                            //aspect-ratio: 1 / 1;
                            overflow: auto;
                        }
                    }
                    &.td-cdraw {
                        background: #000;
                        position: relative;
                        vertical-align: top;
                        .cdraw {
                            background: #000;
                            width: 100%;
                            aspect-ratio: 1 / 1;
                            position: relative;
                        }
                    }
                }
            }
            &.table-inline {
                width: 100%;
                max-width: 100%;
                td {
                    &.td-cmenu {
                        vertical-align: top;
                        width: 347px;
                        max-width: 347px;
                        background: #000;
                        .cmenu {
                            width: 347px;
                            min-width: 347px;
                        }
                    }
                    &.td-cdraw {
                        background: #000;
                        width: 720px;
                        vertical-align: top;
                        .cdraw {
                            position: relative;
                            aspect-ratio: 1 / 1;
                        }
                    }
                    &.td-sheet.column {
                        display: none;
                    }
                    &.td-sheet.inline {
                        vertical-align: top;
                    }
                }
            }
        }
        .sheet-container {
            width: calc(100% - 2px);
        }
    }
}
