.slider-switch {
    .switch {
      position: relative;
      display: inline-block;
      width: 30px; //60px;
      height: 17px; //34px;
    }
  
    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  
    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  
    .slider:before {
      position: absolute;
      content: "";
      height: 13px; //26px;
      width: 13px; //26px;
      left: 2px; //4px;
      bottom: 2px; //4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  
    input:checked + .slider {
      background-color: #00aaaa;
    }
  
    input:focus + .slider {
      box-shadow: 0 0 1px #00aaaa;
    }
  
    input:checked + .slider:before {
      -webkit-transform: translateX(13px);
      -ms-transform: translateX(13px);
      transform: translateX(13px);
    }
  
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
  
    .slider.round:before {
      border-radius: 50%;
    }
  }