.history-control {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 3px;
    margin-inline: auto;
    width: fit-content;
    z-index: 50;
    color: white;
    div {
        user-select: none;
        background: white;
        color: black;
        margin: 0 3px;
        padding: 3px;
        font-size: 110%;
        font-family: "Material Icons";
        &:hover {
            background: yellow;
            cursor: pointer;
            &:active {
                background: red;
                color: white;
            }
        }
        &.disabled {
            background: #555;
            cursor: not-allowed;
            &:active {
                background: #444;
                color: #777;
            }
        }

        &.off {
            background: gray;
            color: #555;
            cursor: not-allowed;
        }
        &.indicator {
            padding: 0 1px;
            margin: 0 1px;
            width: 2px;
            background: #555;
            &.undoLight {
                //background: red;
                animation-name: fadeToRed;
                animation-duration: 200ms;
                animation-fill-mode: forwards;
            }
            &.redoLight {
                animation-name: fadeToYellow;
                animation-duration: 200ms;
                animation-fill-mode: forwards;
            }
        }
        @keyframes fadeToRed {
            from {
                background-color: #555;
            }
            to {
                background-color: red;
            }
        }
        @keyframes fadeToYellow {
            from {
                background-color: #555;
            }
            to {
                background-color: yellow;
            }
        }
    }
}
