// .child-wrapper {
//     container-type: inline-size;
//   }
//   .child {
//     /* Narrow layout stuff here */
//     @container (min-width: 15rem) {
//       /* Wide layout stuff here */
//     }
//   }

.load-counter-control {
    position: relative;
    pointer-events: all;
    .dialog {
        position: absolute;
        z-index: 1001;
        top: 30px;
        border: 1px solid #23a;
        //z-index: 1;
        font-size: 110%;
        background: #013;
        color: white;
        .title {
            white-space: nowrap;
            text-align: center;
            background: #23a;
            padding-bottom: 1px;
            box-shadow:
                0 1px 2px 1px #126,
                inset 1px 1px 2px 1px rgba(255, 255, 255, 0.2);
            display: flex;
            //justify-content: space-between;
            flex-grow: 2;
            //flex-basis: 0;
            //width: 33%;
            //min-width: 33%;
            > div:nth-child(1) {
                width: 16%;
            }
            > div:nth-child(2) {
                width: 84%;
                position: relative;
                top: 3px;
                font-size: 100%;
            }
            > div:nth-child(3) {
                margin-right: 2px;
                &.close-x {
                    button.standard-button.blue {
                        transform: scale(0.7);
                    }
                }
            }
        }
        .select-input {
            //margin: 5px 5px 0 5px;
            font-size: 110%;
            .load-section {
                margin: 20px 14px 17px 14px;
                padding: 10px;
                border: 1px solid cyan;
                outline: 3px solid #055;
                position: relative;
                .load-section-title {
                    position: absolute;
                    background-color: #013;
                    top: -11px;
                    padding: 0 6px 0 4px;
                    font-size: 90%;
                    font-style: italic;
                    color: #0cc;
                    text-shadow: 0 0 #0cc;
                    letter-spacing: 0.05em;
                    span {
                        margin-left: 5px;
                        font-style: normal;
                    }
                }
                .load-input-inline {
                    letter-spacing: 0.01em;
                    padding: 7px 0 6px 4px;
                    display: flex;
                    white-space: nowrap;
                    align-items: center;
                    justify-content: center;
                    select {
                        font-size: 90%;
                        font-family: sans-serif;
                    }
                    div {
                        margin-right: 12px;
                    }
                }
                .warning {
                    color: #dd0;
                    text-align: center;
                    padding-top: 1px;
                }
                &.load-from-file {
                    input[type="file"] {
                        font-family: sans-serif;
                        letter-spacing: 0.01em;
                        font-size: 90%;
                        position: relative;
                        left: 40px;
                        top: 4px;
                        margin: 6px 0 7px 0;
                        &::file-selector-button {
                            text-shadow: 0 0 black;
                            padding: 0 9px 0 10px;
                            margin-right: 10px;
                        }

                    }
                }
            }
            #extractorSvg {
                position: absolute;
                left: -9999;
                top: -9999;
             }
        }
    }
}
