.counter-area-menu {
    width: 100%;
    background-color: black;
    user-select: none;
    padding: 6px 0 9px 0;
    @media screen and (max-width: 800px) {
        padding: 4px 0 7px 0;
    }
    box-shadow:
        0 -1px 1px 2px rgba(120,120,120,0.1),
        inset 0 -3px 3px rgba(200, 200, 200, 0.2),
        inset 0 2px 3px rgba(255, 255, 255, 0.2);
    > .controls {
        display: flex;
        justify-content: space-around;
        @media screen and (max-width: 800px) {
            margin-left: 0px;
            justify-content: flex-start;
        }
        .grid-controls {
            display: flex;
            justify-content: space-around;
            @media screen and (max-width: 800px) {
                transform: scale(0.9);
                margin-left: 0px;
            }
            .grid-control {
                @media screen and (max-width: 800px) {
                    padding: 0;
                    position: relative;
                    left: -6px;
                }
            }
        }
        .control-buttons {
            position: relative;
            top: 1px;
            align-items: center;
            flex-grow: 1;
            display: flex;
            justify-content: space-around;
            font-size: min(calc(1vw + 0.6vw), 100%);
            margin-left: 2px;
            @media screen and (min-width: 1800px) {
                font-size: 95%;
                position: relative;
                left: 0;
                margin-left: 2px;
            }
            @media screen and (max-width: 900px) {
                top: 0;
                font-size: 85%;
                margin-left: 0px;
                position: relative;
                left: -2px;
                margin-left: 1px;
            }
            @media screen and (max-width: 800px) {
               position: relative;
               left: -6px;
            }
        }
    }
}
