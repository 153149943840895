.layers-effects {
    color: white;
    user-select: none;
    .instructions {
        font-size: 90%;
        padding: 6px 0 6px 10px;
    }
    table {
        border-collapse: collapse;
        width: 96%;
        margin-left: 2%;
        background: #322;
        border: 1px solid #8aa;
        padding: 0;
        tr.layer {
            color: white;
            &:nth-child(even) {
                background-color: #232;
            }
            &.display-off {
                background-color: #ddd;
            }
            .control {
                text-align: left;
                button {
                    transform: scale(0.8);
                }
            }
            .label {
                padding: 5px 6px 3px;
                word-break: break-all;
                &.editing {
                    background: #141450;
                }
                .effects-container {
                    display: inline;
                    .effect-radio {
                        background: red;
                    }
                    span.click-drop {
                        display: inline-block;
                        font-size: 90%;
                        margin: 0 3px 2px 9px;
                        border: 1px solid #999;
                        padding: 1px 2px 0 3px;
                        background: rgba(0, 200, 200, 0.3);
                        color: #eee;
                        cursor: pointer;
                        &:hover {
                            color: cyan;
                        }
                        &.activated {
                            background: gray;
                            border: none;
                            background: none;
                            color: #aaf;
                        }
                        &.on {
                            color: rgb(154, 254, 254);
                            text-shadow: 0 0 rgba(220,220,220,0.5);
                            background: rgba(0, 100, 100, 0.2);
                        }
                    }
                    .effect-control {
                        border: 1px solid #228;
                        margin: 5px 10px 10px 10px;
                        border: 1px solid #aaa;
                        // color: black;
                        // background: #aac;
                        // padding: 4px 10px;
                        .effect-name {
                            background: #228;
                            color: #aff;
                            padding: 2px 0 1px 5px;
                            border-bottom: 1px solid #33a;
                            &.off {
                                color: #ddd;
                            }
                            .activate-radiogroup {
                                color: white;
                                padding-left: 10px;
                                position: relative;
                                top: 2px;
                                label {
                                    position: relative;
                                    top: -1px;
                                    margin-right: 5px;
                                }
                            }
                        }
                        .effect-settings {
                            padding: 2px 1px 2px 0px;
                            display: flex;
                            flex-wrap: wrap;
                            .range-label {
                                color: #6cc;
                                display: flex;
                                align-items: center;
                                text-align: right;
                                margin-left: 1%;
                                font-size: 90%;
                                width: 50px;
                            }
                            &.disabled {
                                opacity: 0.5;
                                pointer-events: none;
                            }
                            div {
                                //background: #ccc;
                                display: flex;
                                flex-grow: 1;
                                padding: 0 0 0 10px;
                                white-space: nowrap;
                                justify-content: left;
                                //width: 100%;
                                //align-items: left;
                                input[type="range"] {
                                        margin-left: 9px;
                                    width: 100%;
                                }
                            }
                            .color-pick-container {
                                div.color-pick {
                                    display: flex;
                                    align-items: center;
                                    padding-left: 0;
                                    // display: flex;
                                    // justify-content: left;
                                    input[type="text"] {
                                        width: 83px;
                                        margin-left: 5px;
                                        font-size: 110%;
                                        padding: 0;
                                        &.invalid {
                                            background: #faa;
                                        }
                                    }
                                    .color-sample {
                                        width: 24px;
                                        height: 24px;
                                        max-width: 24px;
                                        margin-left: 7px;
                                        border: 1px solid white;
                                        box-sizing: border-box;
                                        cursor: pointer;
                                    }
                                    .multicolor-button {
                                        width: 28px;
                                        min-width: 28px;
                                        max-width: 28px;
                                        height: 28px;
                                        overflow: hidden;
                                        cursor: pointer;
                                        flex-shrink: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
