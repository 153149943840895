.view-fonts {
    left: 0;
    background: #ddd;
    width: 100%;
    .fonts-general-settings {
        background: #ddeadd;
        position: sticky;
        top: 35px;
        box-shadow: 0 1px 2px 2px rgba(0,0,0,0.5);
        .fonts-installed-count {
            padding: 1px 0 1px 20px;
            font-size: 90%;
            background-color: #dde0ed;
            > button {
                margin-left: 20px;
            }
        }
        .controls {
            display: flex;
            padding: 5px 5px 5px 20px;
            @media screen and (max-width: 1050px) {
                padding: 5px 5px 5px 10px;
            }
            .sample-text {
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                @media screen and (max-width: 1050px) {
                    font-size: 90%;
                }
                > div:first-child {
                    position: relative;
                    margin-right: 3px;
                }
                :nth-child(3) {
                    cursor: pointer;
                    text-shadow: 0 0 rgba(0, 0, 0, 0.5);
                    background: #cdf;
                    font-size: 90%;
                    margin-top: 1px;
                    padding: 1px 4px 2px 4px;
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    &:hover {
                        background: #ffd;
                    }
                }
                input[type="text"] {
                    font-family: sans-serif;
                    font-size: 110%;
                    width: 346px;
                    margin-right: 6px;
                }
            }

            .text-options {
                display: flex;
                user-select: none;
                align-items: flex-end;
                padding-left: 20px;
                @media screen and (max-width: 1020px) {
                    padding-left: 0;
                }
                label {
                    position: relative;
                    top: -2px;
                    margin: 0 10px 0 20px;
                    white-space: nowrap;
                    &:nth-child(2) {
                        margin: 0 15px 0 5px;
                        @media screen and (max-width: 1020px) {
                            margin: 0 8px 0 5px;
                        }
                    }
                    @media screen and (max-width: 1020px) {
                        font-size: 95%;
                        margin: 0 0px 0 10px;
                    }
                }
                input[type="checkbox"] {
                    width: 17px;
                    height: 17px;
                    position: relative;
                    top: 2px;
                    margin-right: 5px;
                    @media screen and (max-width: 1020px) {
                        width: 15px;
                        height: 15px;
                    }
                }
                .range-box {
                    padding: 0 10px;
                    display: flex;
                    align-items: flex-end;
                    padding: 0 6px 3px 0;
                    @media screen and (max-width: 1020px) {
                        font-size: 90%;
                        padding: 0 3px 0 0;
                    }
                    .font-size-label {
                        font-size: 110%;
                        line-height: 105%;
                        margin: 0 5px 2px 0;
                        white-space: nowrap;
                        @media screen and (max-width: 1020px) {
                            margin: 0 4px 3px 0px;
                            font-size: 107%;
                        }

                    }
                    span {
                        color: #00a;
                        @media screen and (max-width: 1020px) {
                            margin-bottom: 2px;

                        }
                    }
                    input[type="range"] {
                        position: relative;
                        width: 50px;
                    }
                }
                input[type="text"] {
                    font-family: sans-serif;
                    height: 70%;
                    font-size: 110%;
                    width: 40px;
                    @media screen and (max-width: 1020px) {
                        height: 70%;
                        font-size: 100%;
                        width: 35px;
                    }
                    &.warning {
                        background: #fdd;
                    }
                }
            }
        }
    }
    .fonts-area {
        width: calc(100% - 4px);
        padding: 2px;
        display: table;
        background: #eee;
        overflow: hidden;
        table {
            border-spacing: 6px;
            width: 100%;
            tbody {
                tr {
                    td {
                        width: 100%;
                        border: 1px solid black;
                        padding: 10px;
                        .font-entry {
                            .first-line {
                                display: flex;
                                justify-content: space-between;
                                .delete-block {
                                    .confirm-delete {
                                        font-size: 90%;
                                        margin-right: 10px;
                                        color: red;
                                        text-shadow: 0 0 red;
                                    }
                                }
                                .font-item {
                                    span {
                                        color: #00d;
                                        text-shadow: 0 0 #00d;
                                        margin-right: 30px;
                                    }
                                }
                            }
                            .source-uri {
                                span {
                                    font-size: 90%;
                                    color: #030;
                                }
                            }
                            .sample {
                                textarea {
                                    width: 99%;
                                    height: 153px;
                                    padding: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
