.storage-usage {
    position: absolute;
    top: 3px;
    right: 22px;
    &.show {
        transition: opacity 200ms;
        opacity: 1;
    }
    &.hide {
        opacity: 0;
    }
    div {
        margin-top: 2px;
        color: #333;

        span {
            color: #077;
        }
        @media screen and (max-width: 620px) {
            display: none;
        }
    }
}
