.input-fill {
    .input-fill-tabs {
        margin: 0 6px;
        .input-fill-tabs-selection {
            .tabs {
                border-bottom: 0;
                .tab {
                    scale: 94%;
                    padding: 3px 10px 5px 8px;
                }
                overflow: hidden;
            }
        }
        .tabbed-content {
            border: 1px solid #555;
            .solid-settings {
                .input-color {
                    padding: 4px 0;
                    position: relative;
                    left: 3px;
                    .solid-fill {
                        margin-left: 0px;
                    }
                    .multicolor-button {
                        width: 28px;
                        min-width: 28px;
                        height: 28px;
                    }
                }
            }
            .gradient-settings {
                transform-origin: 0% 1%;
                animation: slideInFromTop 100ms linear forwards;
                padding: 9px 12px;
                .radio-choice-gradient-direction {
                    display: flex;
                    justify-content: center;
                    padding: 0 0 9px 0;
                    .direction-named {
                        position: relative;
                        top: -1px;
                        margin-right: 3px;
                        color: white;
                    }
                    .radio-options {
                        display: flex;
                        justify-content: center;
                        gap: 8px;
                        label {
                            margin-left: 3px;
                            cursor: pointer;
                            position: relative;
                            top: -1px;
                        }
                        .click-enabled {
                            cursor: pointer;
                            input[type="radio"] {
                                cursor: pointer;
                            }
                        }
                    }
                }
                .input-colors {
                    border-top: 1px solid #555;
                    padding-top: 8px;
                    .input-color {
                        padding-top: 3px;
                        &:nth-child(1) {
                            padding-top: 0;
                        }
                        .order-named {
                            width: 58px;
                            text-align: right;
                        }
                    }
                }
                .input-xy-position_gradient {
                    padding: 7px 0 7px 0;
                    border-top: 1px solid #555;
                    .gradient-named {
                        padding-bottom: 4px;
                        color: white;
                    }
                    .coordinate-container {
                        font-family: sans-serif;
                        display: flex;
                        &.disabled {
                            opacity: 0.5;
                        }
                        span {
                            font-family: sans-serif;
                            margin-right: 5px;
                            position: relative;
                            top: -2px;
                            cursor: pointer;
                        }
                        .slider {
                            width: 80%;
                        }
                        .coordinate-readout {
                            font-family: sans-serif;
                            width: 25px;
                            margin-left: 5px;
                            text-align: right;
                            color: #ccf;
                        }
                    }
                }
            }
        }
    }

    @keyframes slideInFromTop {
        0% {
            transform: scaleY(0);
        }
        100% {
            transform: scaleY(100%);
        }
    }
}
