.grid-color-control {
    display: flex;
    background: #000;
    color: white;
    padding: 0.25rem 0 0 0;
    > .label {
        color: #aaa;
        padding: 0 0.2rem 0 0.2rem;
        white-space: nowrap;
    }
    > .light-text {
        color: #aaa;
        padding: 0 0.4rem 0 0;
        &.selected {
            font-weight: bold;
        }
    }
    > .slider-switch {
        padding-top: 0.13rem;
        > .switch {
            transform: scale(1);
            input:checked + .slider {
                background-color: #aaaaaa;
            }
            input:focus + .slider {
                box-shadow: 0 0 1px #aaaaaa;
            }
        }
    }
    > .dark-text {
        color: #aaa;
        padding: 0 0 0 0.4rem;
        &.selected {
            font-weight: bold;
        }
    }
    &.active {
        > .label {
            color: #fff;
        }
        > .light-text {
            color: cyan;
        }
        > .slider-switch {
            > .switch {
                input:checked + .slider {
                    background-color: #00aaaa;
                }
                input:focus + .slider {
                    box-shadow: 0 0 1px #00aaaa;
                }
            }
        }
        > .dark-text {
            color: cyan;
        }
    }
}
