.top-menu-view {
    position: absolute;
    width: 100%;
    top: 27px;
    .sticky-top {
        padding: 3px 20px 4px 20px;
        border-bottom: 1px solid #888;
        background: #ddf;
        position: sticky;
        top: 0;
        z-index: 1;
        .top {
            display: flex;
            justify-content: space-between;
            position: relative;
            top: 4px;
            color: #000;
            text-shadow: 0 0 black;
            .left-side {
                display: flex;
                div:nth-child(2) {
                        margin-right: 22px;
                        margin-left: 20px;
                        color: #009;
                        font-size: 90%;
                        position: relative;
                        top: 1px;  
                        width: 130px;
                        &.wider {
                            width: 180px;
                        }
                        span {
                            white-space: nowrap;
                        }
                } 
                .standard-button {
                    position: relative;
                    top: -3px;
                    transform: scale(0.9);
                }
            }
            .close-x {
                position: relative;
                top: -3px;
            }

        }
    }
}
