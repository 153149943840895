.input-font {
    padding: 4px 0;
    font-family: sans-serif;
    .named {
        font-family: sans-serif;
    }
    select {
        font-size: 100%;
        margin-left: 5px;
        font-family: sans-serif;
    }
}