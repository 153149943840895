.symbol-group {
  .group-label {
    font-size: 90%;
    padding: 5px 0 5px 10px;
    .hovering-on {
      color: yellow;
      margin-left: 10px;
    }
  }
  .material-symbol-search {
    font-size: 90%;
    margin-left: 10px;
  }
  .symbol-buttons {
    border: 1px solid #0070b8;
    background: #001520;
    padding: 1px 0 3px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    grid-gap: 5px;
    height: 200px;
    overflow-y: auto;
  }
  .symbol-buttons::after {
    content: "";
    flex: auto;
  }
  .google-material::-webkit-scrollbar-thumb {
    background-color: #05a0e8;
  }
}
