.layers-display {
    color: white;
    .instructions {
        font-size: 90%;
        padding: 6px 0 6px 10px;
    }
    table {
        border-collapse: collapse;
        width: 96%;
        margin-left: 2%;
        background: #222;
        border: 1px solid #8aa;
        padding: 0;
        tr.header {
                background: black;
                color: cyan;
                td {
                    &:first-child {
                        text-align: center;
                    }
                    &:nth-child(2) {
                        text-align: left;
                        padding-left: 6px;
                    }
                }
            }
        tr.layer {
            color: white;
            &:nth-child(even) { 
                background-color: #006; 
            } 
            &.display-off {
                background-color: #ddd;
            }
            .control {
                text-align: center;
            }
            .label {
                padding-left: 6px;
                word-break: break-all;
            }
        }    
        
        tr {
            td {
               padding: 1px 0 2px 0;
               border-top: 1px solid #555;
            }
            td:first-child {
                padding: 0;
                border-right: 1px solid rgba(255,255,255,0.5);
            }
            &.header {
                td {
                    padding-left: 10px;
                    border-top: 0.13rem solid #888;
                }
                td:first-child {
                    text-align: center;
                    padding-left: 0;
                }
                background: #400;
                color: white;
            }
        }
     }
}
