.color-picker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    .hex-color-container {
      position: absolute;
      z-index: 2;
      left: 100px;
      top: 200px;
      padding: 5px;
      border: 2px solid green;
      border-radius: 10px;
      background-color: white;
      width: 200px;
      animation:100ms ease 0s normal forwards 1 fadein;
      @keyframes fadein {
        0% {
          opacity: 0;
        }
        80% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
  
      @-webkit-keyframes fadein {
        0% {
          opacity: 0;
        }
        80% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
  
      .manual-color {
        margin-top: 5px;
        display: flex;
        background: white;
        .manual-label {
          line-height: 190%;
          margin-right: 3px;
        }
        input {
          width: 70px;
          font-size: 120%;
          background: #e7f6ff;
          border: 1px solid rgb(60, 60, 236);
          outline: 1px solid rgb(220, 220, 255);
        }
      }
      .react-colorful {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 200px;
        height: 200px;
        user-select: none;
        cursor: default;
      }
  
      .react-colorful__saturation {
        position: relative;
        flex-grow: 1;
        border-color: transparent; /* Fixes https://github.com/omgovich/react-colorful/issues/139 */
        border-bottom: 12px solid #000;
        border-radius: 8px 8px 0 0;
        background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)),
          linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
      }
  
      .react-colorful__pointer-fill,
      .react-colorful__alpha-gradient {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        border-radius: inherit;
      }
  
      /* Improve elements rendering on light backgrounds */
      .react-colorful__alpha-gradient,
      .react-colorful__saturation {
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
      }
  
      .react-colorful__hue,
      .react-colorful__alpha {
        position: relative;
        height: 24px;
      }
  
      .react-colorful__hue {
        background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
      }
  
      /* Round bottom corners of the last element: `Hue` for `ColorPicker` or `Alpha` for `AlphaColorPicker` */
      .react-colorful__last-control {
        border-radius: 0 0 8px 8px;
      }
  
      .react-colorful__interactive {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        outline: none;
        /* Don't trigger the default scrolling behavior when the event is originating from this element */
        touch-action: none;
      }
  
      .react-colorful__pointer {
        position: absolute;
        z-index: 1;
        box-sizing: border-box;
        width: 28px;
        height: 28px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border: 2px solid #fff;
        border-radius: 50%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }
  
      .react-colorful__interactive:focus .react-colorful__pointer {
        transform: translate(-50%, -50%) scale(1.1);
      }
  
      /* Chessboard-like pattern for alpha related elements */
      .react-colorful__alpha,
      .react-colorful__alpha-pointer {
        background-color: #fff;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><rect x="8" width="8" height="8"/><rect y="8" width="8" height="8"/></svg>');
      }
  
      /* Display the saturation pointer over the hue one */
      .react-colorful__saturation-pointer {
        z-index: 3;
      }
  
      /* Display the hue pointer over the alpha one */
      .react-colorful__hue-pointer {
        z-index: 2;
      }
    }
  }
  