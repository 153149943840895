.menu-items {
    display: flex;
    gap: 10px;
    padding: 5px 0 0px 20px; 
    background: rgb(234,238,251);
    >div {
        cursor: pointer;
        &.active {
            color: red;
        }
    }
    >span.app-title {
        margin-left: 10%;
        color: blue;
        font-style: italic;
        text-shadow: 1px 1px rgba(0,0,250,0.1);
        letter-spacing: 0.03rem;
        @media screen and (max-width: 800px) {
            display: none;
        }
    }
}